'use client';

import clsx from 'clsx';
import React from 'react';
import { Button, ButtonProps } from 'antd';
import './tik-tok-button.css';

export const TikTokButton: React.FC<ButtonProps> = ({
  className,
  ...props
}) => {
  return <Button className={clsx(className, 'tik-tok-button')} {...props} />;
};
