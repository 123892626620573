import React from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const SecuritySvg = () => (
  <svg
    width="18"
    height="20"
    fill="none"
    viewBox="0 0 18 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="black"
      d="M9.018 0C6.1922 1.47682 3.14735 2.48974 0 3V8.889C0 15.556 5.667 18.889 9 20C12.333 18.889 18 15.556 18 8.889V3C14.8621 2.49768 11.8279 1.48423 9.018 0ZM12.542 8.53L8.542 12.53C8.47235 12.5997 8.38965 12.655 8.29862 12.6927C8.2076 12.7304 8.11003 12.7498 8.0115 12.7498C7.91297 12.7498 7.8154 12.7304 7.72438 12.6927C7.63335 12.655 7.55065 12.5997 7.481 12.53L5.481 10.53C5.34445 10.3885 5.26894 10.199 5.27074 10.0023C5.27254 9.8057 5.35151 9.61763 5.49063 9.47864C5.62975 9.33965 5.8179 9.26086 6.01455 9.25924C6.2112 9.25763 6.40061 9.33331 6.542 9.47L8.012 10.939L11.481 7.47C11.6224 7.33331 11.8118 7.25763 12.0085 7.25924C12.2051 7.26086 12.3932 7.33965 12.5324 7.47864C12.6715 7.61763 12.7505 7.8057 12.7523 8.00235C12.7541 8.199 12.6786 8.38848 12.542 8.53Z"
    />
  </svg>
);

export const SecurityIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SecuritySvg} {...props} />
);
