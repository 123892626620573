import type { ThemeConfig } from 'antd';
import { generate } from '@ant-design/colors';

export const colors = generate('#5956e9');

export const prefixCls = 'mediakit';

export const slowAnimation = '1s';

export const fastAnimation = '.4s';

export const fastAnimationMS = 400;

export const breakpoints = {
  xs: '479px',
  sm: '576px',
  md: '768px',
  lg: '991px',
  xl: '1200px',
  xxl: '1600px',
};

export const builderBreakpoint = '1210px';

export const spaces = {
  space4: '.4rem',
  space8: '.8rem',
  space12: '1.2rem',
  space16: '1.6rem',
  space18: '1.8rem',
  space24: '2.4rem',
  space26: '2.6rem',
  space32: '3.2rem',
  space48: '4.8rem',
};

export const Neutral = {
  gray1: '#ffffff',
  gray2: '#fafafa',
  gray3: '#f5f5f5',
  gray4: '#f0f0f0',
  gray5: '#d9d9d9',
  gray6: '#bfbfbf',
  gray7: '#8c8c8c',
  gray8: '#595959',
  gray9: '#434343',
  gray10: '#262626',
  gray11: '#1f1f1f',
  gray12: '#141414',
  gray13: '#000000',
};

export const lightBoxShadow =
  '0px 2px 4px 0px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.03)';

export const gridItemShadow =
  '0 2px 4px 0 rgba(0, 0, 0, 0.02), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 1px 2px 0 rgba(0, 0, 0, 0.03)';

export const boxShadowTertiary =
  '0px 2px 4px 0px rgba(0, 0, 0, 0.02), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.03)';

export const lightBoxShadowSecondary =
  '0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px 0px rgba(0, 0, 0, 0.08)';

export const shadow =
  '0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)';

const theme: ThemeConfig = {
  components: {
    Layout: {
      bodyBg: '#fff',
      headerBg: '#fff',
    },
  },
  token: {
    marginLG: 24,
    paddingXL: 32,
    colorPrimary: colors[5],
    colorBgElevated: '#ffffff',
    colorBgLayout: Neutral.gray3,
    colorBorderSecondary: 'rgba(0, 0, 0, 0.06)',
  },
  cssVar: {
    prefix: 'mediakit',
  },
  hashed: true,
};

// 64
export const previewTop = 64;

// 0
export const previewMTDiff = 0;

// 80
export const previewHeightDiff = 150;

// 32 + 64 = 96
export const previewTopDiff = 32 + previewTop;

export default theme;
