import React from 'react';
import { Flex } from 'antd';
import Link from 'next/link';
import Image from 'next/image';
import { Handle } from './handle';
import { HeadDetails } from './head-details';
import linkIcon from '@shared/icons/link.svg';
import { Builder } from '@shared/types/builder';
import igIcon from '@shared/icons/instagram.svg';
import { HandleSkeleton } from './handle-skeleton';
import { Text, Title } from '@shared/ui/typography';
import { SocialCardBody } from './social-card-body';
import { SocialCardHead } from './social-card-head';
import { MemoInstagramPosts } from './instagram-posts';
import { FollowersSkeleton } from './followers-skeleton';
import { SocialIconSkeleton } from './social-icon-skeleton';
import { fontVariants } from '@shared/constants/fontVariants';
import { ConnectedInstagram } from '@features/connect-instagram';
import { abbreviateNumber } from '@shared/utils/abbreviateNumber';
import { builderCardSizes } from '@shared/constants/builderCardSizes';

export const InstagramCard: React.FC<{
  user?: any;
  dataServer?: boolean;
  showSkeleton?: boolean;
  activeSize: builderCardSizes;
  data: Builder.GridComponentProps['data'];
}> = ({ data, dataServer, showSkeleton, activeSize: size, user }) => {
  let style = {};
  let block = false;
  let label = <span style={{ fontSize: 14 }}>Connect Instagram</span>;

  const hasLinkedInstagram = user?.linked_accounts?.instagram;

  if ([builderCardSizes.s14, builderCardSizes.s12].includes(size)) {
    block = true;
    style = { marginTop: 4 };
    label = <span style={{ fontSize: 14 }}>Connect</span>;
  }

  return (
    <SocialCardBody size={size}>
      <SocialCardHead size={size}>
        <HeadDetails size={size}>
          {showSkeleton ? (
            <SocialIconSkeleton size={40} active={showSkeleton} />
          ) : (
            <Image src={igIcon} alt="Instagram" />
          )}
          <Handle>
            {dataServer ? (
              <Flex align="center" gap={4}>
                <Text ellipsis type="secondary" className={fontVariants.opt2}>
                  @{data?.handle}
                </Text>
                <Image src={linkIcon} alt="Open in new window" />
              </Flex>
            ) : (
              !!data?.link && (
                <HandleSkeleton
                  paragraph={false}
                  active={showSkeleton}
                  loading={showSkeleton}
                >
                  <Link target="_blank" href={data.link}>
                    <Flex align="center" gap={4}>
                      <Text
                        ellipsis
                        type="secondary"
                        className={fontVariants.opt2}
                      >
                        @{data?.handle}
                      </Text>
                      <Image src={linkIcon} alt="Open in new window" />
                    </Flex>
                  </Link>
                </HandleSkeleton>
              )
            )}
          </Handle>
        </HeadDetails>

        {dataServer ? (
          <FollowersSkeleton
            size={size}
            paragraph={false}
            active={showSkeleton}
            loading={showSkeleton}
          >
            <Title level={1} ellipsis className={fontVariants.opt2}>
              {abbreviateNumber(data?.followers || 0)}
            </Title>
          </FollowersSkeleton>
        ) : !hasLinkedInstagram && !showSkeleton ? (
          <ConnectedInstagram block={block} label={label} style={style} />
        ) : (
          <FollowersSkeleton
            size={size}
            paragraph={false}
            active={showSkeleton}
            loading={showSkeleton}
          >
            <Title level={1} ellipsis className={fontVariants.opt2}>
              {abbreviateNumber(data?.followers || 0)}
            </Title>
          </FollowersSkeleton>
        )}
      </SocialCardHead>

      <MemoInstagramPosts
        size={size}
        posts={data?.posts || []}
        showSkeleton={showSkeleton}
      />
    </SocialCardBody>
  );
};
