import React from 'react';
import { Box } from '@shared/ui/box';
import styled from '@emotion/styled';
import { prefixCls } from '@shared/theme';
import { Skeleton, Typography } from 'antd';
import { Title } from '@shared/ui/typography';
import { Builder } from '@shared/types/builder';
import { RiseOutlined } from '@ant-design/icons';
import { fontVariants } from '@shared/constants/fontVariants';
import { ComponentNames } from '@shared/constants/componentNames';
import { builderCardSizes } from '@shared/constants/builderCardSizes';

const list: Record<string, boolean> = {
  [ComponentNames.instagram_followers]: true,
  [ComponentNames.instagram_avg_likes]: true,
  [ComponentNames.instagram_avg_comments]: true,
  [ComponentNames.instagram_engagement_rate]: true,
};

const typographyStyle: React.CSSProperties = {
  left: 16,
  bottom: 12,
  fontSize: 12,
  paddingRight: 24,
  position: 'absolute',
};

export const MKitCard = ({
  data,
  icon,
  user,
  value,
  title,
  actions,
  dataGrid,
  activeItem,
  activeSize,
  dataServer,
  showSkeleton,
}: Builder.GridComponentProps & {
  title?: string;
  value?: string;
  activeSize: any;
  activeItem?: string;
  showSkeleton?: boolean;
  icon?: React.ReactNode;
  actions?: React.ReactNode;
  allowedSizes: builderCardSizes[];
}) => {
  const isActive = activeItem === dataGrid.i;

  const showMessage =
    !dataServer &&
    !showSkeleton &&
    list[data.name] &&
    value?.startsWith('0') &&
    !!user?.linked_accounts?.instagram;

  return (
    <MKitCardContainer
      key={dataGrid.i}
      data-grid={dataGrid}
      className={isActive ? 'active-item' : ''}
    >
      {showMessage && (
        <Typography.Text ellipsis type="secondary" style={typographyStyle}>
          Your data will be displayed within{' '}
          <Typography.Text strong type="secondary" style={{ fontSize: 12 }}>
            1h.
          </Typography.Text>
        </Typography.Text>
      )}
      <MKitCardHead>
        <MKitCardHeadSkeleton
          active
          paragraph={false}
          showSkeleton={showSkeleton}
        />

        <CardTitle size={activeSize} showSkeleton={showSkeleton}>
          {icon}
          <Typography.Text className={fontVariants.opt3}>
            {title}
          </Typography.Text>
        </CardTitle>
      </MKitCardHead>

      <MKitCardBody>
        {showSkeleton ? (
          <MKitCardBodySkeleton
            active
            paragraph={false}
            showSkeleton={showSkeleton}
          />
        ) : (
          <MKitCardMeta size={activeSize} showSkeleton={showSkeleton}>
            <Typography.Title ellipsis level={1} className={fontVariants.opt2}>
              {value}
            </Typography.Title>

            <Trends trends={data?.trends || 0} activeSize={activeSize} />
          </MKitCardMeta>
        )}
      </MKitCardBody>
      {!!actions && actions}
    </MKitCardContainer>
  );
};

const MKitCardContainer = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  padding: '1.6rem',
  borderRadius: '2.8rem',
  flexDirection: 'column',
}));

const MKitCardHead = styled('div')(() => ({
  gap: '.4rem',
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
}));

const MKitCardBody = styled('div')(() => ({
  top: 0,
  left: 0,
  flexGrow: 1,
  gap: '.8rem',
  width: '100%',
  height: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  paddingTop: '1.6rem',
  paddingInline: '1.6rem',
}));

const MKitCardMeta = styled('div', {
  shouldForwardProp: (prop) => !['size', 'showSkeleton'].includes(prop),
})(
  ({
    size,
    showSkeleton,
  }: {
    size: builderCardSizes;
    showSkeleton?: boolean;
  }) => ({
    gap: '.8rem',
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'baseline',
    opacity: showSkeleton ? 0 : 1,
    [`& h1.${prefixCls}-typography`]: {
      marginBottom: 0,
      fontSize: builderCardSizes.s12 === size ? '3.5rem' : '5.5rem',
    },
  }),
);

const CardTitle = styled('div', {
  shouldForwardProp: (prop) => !['size', 'showSkeleton'].includes(prop),
})(
  ({
    size,
    showSkeleton,
  }: {
    showSkeleton?: boolean;
    size: builderCardSizes;
  }) => ({
    gap: '.4rem',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    opacity: showSkeleton ? 0 : 1,
    [`& > span.${prefixCls}-typography`]: {
      marginBottom: 0,
      whiteSpace: 'nowrap',
      fontSize: [builderCardSizes.s12, builderCardSizes.s14].includes(size)
        ? '1.2rem'
        : '1.6rem',
    },
  }),
);

const MKitCardHeadSkeleton = styled(Skeleton, {
  shouldForwardProp: (prop) => !['showSkeleton'].includes(prop),
})<{ showSkeleton?: boolean }>(({ showSkeleton }) => ({
  display: showSkeleton ? 'table' : 'none',
  [`& .${prefixCls}-skeleton-content .${prefixCls}-skeleton-title`]: {
    width: 111,
    height: 24,
    marginBottom: 0,
  },
}));

const MKitCardBodySkeleton = styled(Skeleton, {
  shouldForwardProp: (prop) => !['showSkeleton'].includes(prop),
})<{ showSkeleton?: boolean }>(({ showSkeleton }) => ({
  display: showSkeleton ? 'table' : 'none',
  [`& .${prefixCls}-skeleton-content .${prefixCls}-skeleton-title`]: {
    height: 46,
    maxWidth: 270,
    marginBottom: 0,
  },
}));

const Trends = ({
  trends,
  activeSize,
}: {
  trends?: number;
  activeSize: builderCardSizes;
}) => {
  let fixed = parseFloat(((trends || 0) * 100).toFixed(1));

  return !!trends &&
    fixed > 0.1 &&
    trends >= 0 &&
    activeSize !== builderCardSizes.s12 ? (
    <Title
      level={3}
      type="success"
      className={fontVariants.opt2}
      titleProps={{ margin: 0, whiteSpace: 'nowrap' }}
    >
      <Box
        boxStyles={{
          gap: '.4rem',
          width: 'unset',
          display: 'inline-flex',
        }}
      >
        <RiseOutlined />
        {(trends * 100).toFixed(1)}%
      </Box>
    </Title>
  ) : null;
};
