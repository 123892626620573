import React from 'react';
import { Builder } from '@shared/types/builder';
import { useSelector, selectActiveItem } from '@shared/redux';
import { builderCardSizes } from '@shared/constants/builderCardSizes';
import { InstagramCardBasic } from '@widgets/social-card/ui/instagram-card-basic';
import { SocialCardContainer } from '@widgets/social-card/ui/social-card-container';
import { MoveAction } from '@widgets/mediakit/MediaKit/Common/Actions/ui/move-action';
import { DeleteAction } from '@widgets/mediakit/MediaKit/Common/Actions/ui/delete-action';

export function ProfileBasic({
  data,
  user,
  index,
  dataGrid,
  dataServer,
  showSkeleton,
}: Builder.GridComponentProps) {
  const activeItem = useSelector(selectActiveItem);
  const className = activeItem === dataGrid.i ? 'active-item' : '';
  const activeSize = `${dataGrid.w}${dataGrid.h}` as builderCardSizes;

  const isActive = activeItem === dataGrid.i;

  return (
    <SocialCardContainer
      key={dataGrid.i}
      data-grid={dataGrid}
      className={className}
    >
      <MoveAction isActive={isActive} index={index} />
      <DeleteAction id={dataGrid.i} isActive={isActive} index={index} />

      <InstagramCardBasic
        data={data}
        user={user}
        activeSize={activeSize}
        dataServer={dataServer}
        showSkeleton={showSkeleton}
      />
    </SocialCardContainer>
  );
}
