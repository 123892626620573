import clsx from 'clsx';
import React from 'react';
import { Builder } from '@shared/types/builder';
import { GridItem } from '@shared/ui/GridItem';
import { dynamicCards } from '@shared/constants';
import { Layout as ILayout } from 'react-grid-layout';
import * as Basics from '@widgets/mediakit/MediaKit/Basics';
import { ComponentNames } from '@shared/constants/componentNames';
import * as TikTok from '@widgets/mediakit/MediaKit/Socials/TikTok';
import * as YouTube from '@widgets/mediakit/MediaKit/Socials/YouTube';
import * as Instagram from '@widgets/mediakit/MediaKit/Socials/Instagram';

export const components: Record<string, (props: any) => React.JSX.Element> = {
  [ComponentNames.tiktok_profile]: TikTok.Profile,
  [ComponentNames.youtube_profile]: YouTube.Profile,
  [ComponentNames.instagram_profile]: Instagram.Profile,
  [ComponentNames.instagram_profile_basic]: Instagram.ProfileBasic,

  [ComponentNames.tiktok_audience_genders]: TikTok.SexRatio,
  [ComponentNames.tiktok_avg_likes]: TikTok.AccountComponent,
  [ComponentNames.tiktok_top_countries]: TikTok.TopCountries,
  [ComponentNames.tiktok_avg_saves]: TikTok.AccountComponent,
  [ComponentNames.tiktok_avg_views]: TikTok.AccountComponent,
  [ComponentNames.tiktok_followers]: TikTok.AccountComponent,
  [ComponentNames.tiktok_avg_shares]: TikTok.AccountComponent,
  [ComponentNames.tiktok_avg_comments]: TikTok.AccountComponent,
  [ComponentNames.tiktok_engagement_rate]: TikTok.AccountComponent,
  [ComponentNames.tiktok_likes_growth]: TikTok.LikesFollowersGrowth,
  [ComponentNames.tiktok_audience_genders_per_age]: TikTok.SexAgeRatio,
  [ComponentNames.tiktok_followers_growth]: TikTok.LikesFollowersGrowth,

  [ComponentNames.instagram_audience_genders]: Instagram.SexRatio,
  [ComponentNames.instagram_avg_views]: Instagram.AccountComponent,
  [ComponentNames.instagram_followers]: Instagram.AccountComponent,
  [ComponentNames.instagram_avg_likes]: Instagram.AccountComponent,
  [ComponentNames.instagram_avg_comments]: Instagram.AccountComponent,
  [ComponentNames.instagram_engagement_rate]: Instagram.AccountComponent,
  [ComponentNames.instagram_avg_reels_plays]: Instagram.AccountComponent,
  [ComponentNames.instagram_likes_growth]: Instagram.LikesFollowersGrowth,
  [ComponentNames.instagram_audience_genders_per_age]: Instagram.SexAgeRatio,
  [ComponentNames.instagram_followers_growth]: Instagram.LikesFollowersGrowth,
  [ComponentNames.instagram_top_countries]: Instagram.TopCountries,
  [ComponentNames.instagram_top_cities]: Instagram.TopCities,

  [ComponentNames.youtube_audience_genders]: YouTube.SexRatio,
  [ComponentNames.youtube_followers]: YouTube.AccountComponent,
  [ComponentNames.youtube_avg_views]: YouTube.AccountComponent,
  [ComponentNames.youtube_top_countries]: YouTube.TopCountries,
  [ComponentNames.youtube_avg_likes]: YouTube.AccountComponent,
  [ComponentNames.youtube_avg_comments]: YouTube.AccountComponent,
  [ComponentNames.youtube_engagement_rate]: YouTube.AccountComponent,
  [ComponentNames.youtube_views_growth]: YouTube.ViewsFollowersGrowth,
  [ComponentNames.youtube_audience_genders_per_age]: YouTube.SexAgeRatio,
  [ComponentNames.youtube_followers_growth]: YouTube.ViewsFollowersGrowth,

  [ComponentNames.basics_link]: Basics.Link,
  [ComponentNames.basics_image]: Basics.Image,
  [ComponentNames.basics_video]: Basics.Video,
  [ComponentNames.basics_title]: Basics.Title,
  [ComponentNames.basics_text]: Basics.TextComponent,
  [ComponentNames.basics_info_card]: Basics.InfoCard,
  [ComponentNames.basics_service_list]: Basics.ServiceList,
};

export const cols = { sm: 4, xxs: 2 };

export const gridBreakpoints = {
  sm: 748,
  xxs: 0,
};

export function generateDOM({
  user,
  theme,
  handle,
  layout,
  onSelect,
  extraData,
  dataServer,
  activeItem,
  showSkeleton,
  disableSkeleton,
  allElementsAreSelected,
}: {
  user: any;
  theme: any;
  handle?: string;
  layout: ILayout[];
  activeItem?: string;
  dataServer?: boolean;
  showSkeleton?: boolean;
  disableSkeleton?: boolean;
  extraData: Builder.ExtraData;
  allElementsAreSelected?: boolean;
  onSelect?: (index: string) => void;
}) {
  function onClick(i: string) {
    if (typeof onSelect === 'function' && activeItem !== i) {
      onSelect(i);
    }
  }

  return layout.map((dataGrid, index) => {
    const elemKey = dataGrid.i.split('_+_')[0] as ComponentNames;

    const Card = components[elemKey];
    let cardData = extraData[elemKey];

    if (dynamicCards.includes(elemKey)) {
      cardData = extraData[dataGrid.i];
    }

    return !!Card ? (
      <GridItem
        key={dataGrid.i}
        data-i={dataGrid.i}
        data-elem={elemKey}
        data-data={cardData}
        data-server={dataServer}
        onClick={() => onClick(dataGrid.i)}
        className={clsx(
          dataServer && 'server',
          activeItem === dataGrid.i && 'active',
          allElementsAreSelected && 'all-selected',
          elemKey === ComponentNames.basics_title && 'title-component',
        )}
      >
        <Card
          user={user}
          theme={theme}
          index={index}
          data={cardData}
          handle={handle}
          key={dataGrid.i}
          dataGrid={dataGrid}
          dataServer={dataServer}
          showSkeleton={showSkeleton}
          disableSkeleton={disableSkeleton}
        />
      </GridItem>
    ) : null;
  });
}

export const useOverflow = () => {
  React.useEffect(() => {
    if (document) {
      document.body.style.overflowX = 'hidden';
    }

    return () => {
      if (document) {
        document.body.style.overflowX = 'initial';
      }
    };
  }, []);

  return null;
};
