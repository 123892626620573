export enum PosthogEvents {
  login = 'login',
  google_login_click = 'google_login_click',
  AddedBio = 'added_bio',
  InfoCard = 'info_card',
  AddedName = 'added_name',
  TourClosed = 'tour_closed',
  LinkCopied = 'link_copied',
  static = 'background_upload',
  NotifyClick = 'notify_click',
  ImageChange = 'image_change',
  TallyClosed = 'tally_closed',
  VideoChange = 'video_change',
  ServiceList = 'service_list',
  ContactForm = 'contact_form',
  HomeClicked = 'home_clicked',
  AddedSocials = 'added_socials',
  UpgradeClick = 'upgrade_click',
  AddedCountry = 'added_country',
  ShareClicked = 'share_clicked',
  ThemingColor = 'theming_color',
  ThemingClick = 'theming_click',
  ThemingFonts = 'theming_fonts',
  ImageAddLink = 'image_add_link',
  VideoAddLink = 'video_add_link',
  RegisterStep1 = 'register_step1',
  RegisterStep2 = 'register_step2',
  RegisterStep3 = 'register_step3',
  VerifyClicked = 'verify_clicked',
  TourCompleted = 'tour_completed',
  AddedCategory = 'added_category',
  TextComponent = 'text_component',
  unsplash = 'background_unsplash',
  LinkComponent = 'link_component',
  SeeAllClicked = 'see_all_clicked',
  CreateMkClick = 'create_mk_click',
  TitleComponent = 'title_component',
  VideoComponent = 'video_component',
  TallyCompleted = 'tally_completed',
  ImageComponent = 'image_component',
  CategorySearch = 'category_search',
  BackgroundType = 'background_type',
  AddCompClicked = 'add_comp_clicked',
  SwitcherClicked = 'switcher_clicked',
  SelectedScratch = 'selected_scratch',
  viral_mango = 'background_viralmango',
  GetStartedClick = 'get_started_click',
  TemplatesClick = 'templates_click_db',
  ServiceListEdit = 'service_list_edit',
  GalleryComponent = 'gallery_component',
  SelectedTemplate = 'selected_template',
  DeletedComponent = 'deleted_component',
  CreateNewClicked = 'create_new_clicked',
  ThemingBackground = 'theming_background',
  RegisterFromPopup = 'register_from_popup',
  PartnerBtnClicked = 'partner_btn_clicked',
  ServiceListToggle = 'service_list_toggle',
  OnboardingComplete = 'onboarding_complete',
  ServiceListSuccess = 'service_list_success',
  EngagedWithMediakit = 'engaged_with_mediakit',
  ServiceListAddRates = 'service_list_add_rates',
  MoreThanAllowedSize = 'more_than_allowed_size',
  google_register_click = 'google_register_click',
  LinkComponentSuccess = 'link_component_success',
  ImageComponentSuccess = 'image_component_success',
  CloseRegisterFromPopup = 'close_register_from_popup',

  instagramMcClick = 'instagram_mc_click',
  instagramFfcClick = 'instagram_ffc_click',
  instagramErcClick = 'instagram_erc_click',

  instagramMcSubmit = 'instagram_mc_submit',
  instagramFfcSubmit = 'instagram_ffc_submit',
  instagramErcSubmit = 'instagram_erc_submit',

  tiktokErcClick = 'tiktok_erc_click',
  tiktokErcSubmit = 'tiktok_erc_submit',

  clickNewMediaKitDb = 'click_new_media_kit_db',
  click_home_db = 'click_home_db',
  click_mediakits_db = 'click_mediakits_db',
  click_on_mk_db = 'click_on_mk_db',
  click_user_db = 'click_user_db',
  click_user = 'click_user',
  click_contact_db = 'click_contact_db',
  click_see_all_temp_db = 'click_see_all_temp_db',
  click_copy_link = 'click_copy_link',
  click_insights = 'click_insights',
  click_verify = 'click_verify',
  click_create_scratch = 'click_create_scratch',
  create_scratch_success = 'create_scratch_success',
  click_templates = 'click_templates',
  templates_change = 'templates_change',
  templates_change_success = 'templates_change_success',
  click_edit_profile = 'click_edit_profile',
  edit_save = 'click_edit_profile',
  edit_cancel = 'click_edit_profile',
  click_any_component = 'click_any_component',
  enter_handle_ig = 'enter_handle_ig',
  connect_ig_click = 'connect_ig_click',
  ig_popup_close = 'ig_popup_close',
  ig_popup_continue_click = 'ig_popup_continue_click',
  connect_ig_success = 'connect_ig_success',
  disconnect_ig = 'disconnect_ig',

  enter_handle_tt = 'enter_handle_tt',
  connect_tt_click = 'connect_tt_click',
  tt_popup_close = 'tt_popup_close',
  tt_popup_continue_click = 'tt_popup_continue_click',
  connect_tt_success = 'connect_tt_success',
  disconnect_tt = 'disconnect_tt',

  enter_handle_yt = 'enter_handle_yt',

  bio_show_more = 'bio_show_more',

  show_more_bottom_actions = 'show_more_bottom_actions',
}
