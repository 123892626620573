import React from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const ServiceListSvg = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5529 18.9093H13.4547C12.8525 18.9093 12.3638 19.398 12.3638 20.0002C12.3638 20.6024 12.8525 21.0911 13.4547 21.0911H14.9094V22.182C14.9094 22.7842 15.3981 23.2729 16.0003 23.2729C16.6025 23.2729 17.0912 22.7842 17.0912 22.182V21.0373C18.534 20.7813 19.6366 19.5218 19.6366 17.9944C19.6366 16.2942 18.2532 14.9107 16.5529 14.9107H15.4475C14.9502 14.9107 14.5457 14.5064 14.5457 13.9944C14.5457 13.4971 14.9502 13.0925 15.4475 13.0925H18.5457C19.1479 13.0925 19.6366 12.6038 19.6366 12.0016C19.6366 11.3995 19.1479 10.9094 18.5457 10.9094H17.0912V9.81845C17.0912 9.21627 16.6025 8.72754 16.0003 8.72754C15.3981 8.72754 14.9094 9.21627 14.9094 9.81845V10.9632C13.4664 11.2207 12.3638 12.4789 12.3638 14.0076C12.3638 15.7079 13.7472 17.0913 15.4474 17.0913H16.5529C17.0505 17.0913 17.4548 17.4956 17.4548 18.0074C17.4548 18.505 17.0505 18.9093 16.5529 18.9093Z"
      fill="white"
    />
    <path
      d="M16.0001 31.9999C24.8219 31.9999 31.9999 24.8219 31.9999 16.0001C31.9999 7.17826 24.8219 0 16.0001 0C7.17826 0 0 7.17826 0 16.0001C0 24.8219 7.17826 31.9999 16.0001 31.9999ZM16.0001 2.18182C23.6189 2.18182 29.8181 8.38105 29.8181 16.0001C29.8181 23.6189 23.6189 29.8181 16.0001 29.8181C8.38105 29.8181 2.18182 23.6189 2.18182 16.0001C2.18182 8.38105 8.38105 2.18182 16.0001 2.18182Z"
      fill="white"
    />
  </svg>
);

export const ServiceListIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ServiceListSvg} {...props} />
);
