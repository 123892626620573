import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { Handle } from './handle';
import { HeadDetails } from './head-details';
import linkIcon from '@shared/icons/link.svg';
import { Builder } from '@shared/types/builder';
import igIcon from '@shared/icons/instagram.svg';
import { Flex, Skeleton, Typography } from 'antd';
import fallback from '@shared/icons/fallback.png';
import { HandleSkeleton } from './handle-skeleton';
import { Text, Title } from '@shared/ui/typography';
import { SocialCardBody } from './social-card-body';
import { SocialCardHead } from './social-card-head';
import { SocialIconSkeleton } from './social-icon-skeleton';
import { fontVariants } from '@shared/constants/fontVariants';
import { abbreviateNumber } from '@shared/utils/abbreviateNumber';
import { builderCardSizes } from '@shared/constants/builderCardSizes';
import { selectAuthUserLinkedAccounts, useSelector } from '@shared/redux';
import { ConnectedInstagram } from '@features/connect-instagram';

const textStyles: React.CSSProperties = {
  fontSize: 12,
  fontWeight: 600,
};

export const InstagramCardBasic: React.FC<{
  user?: any;
  dataServer?: boolean;
  showSkeleton?: boolean;
  activeSize: builderCardSizes;
  data: Builder.GridComponentProps['data'];
}> = ({ data, dataServer, showSkeleton, activeSize: size, user }) => {
  const linkedAccounts = useSelector(selectAuthUserLinkedAccounts);

  let hasLinkedInstagram = linkedAccounts?.instagram;
  if (dataServer) {
    hasLinkedInstagram = user?.linked_accounts?.instagram;
  }

  return (
    <SocialCardBody size={size}>
      {hasLinkedInstagram ? (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 32 }}>
          <SocialCardHead size={size} style={{ alignItems: 'center' }}>
            <HeadDetails size={size}>
              {showSkeleton ? (
                <SocialIconSkeleton size={40} active={showSkeleton} />
              ) : (
                <Image src={igIcon} alt="Instagram" />
              )}
            </HeadDetails>

            <Skeleton
              paragraph={false}
              active={showSkeleton}
              style={{ width: 40 }}
              loading={showSkeleton}
              title={{ style: { marginBottom: 0 } }}
            >
              <Typography.Text type="secondary" style={textStyles}>
                {data.account_type === 'MEDIA_CREATOR'
                  ? 'Media Creator'
                  : 'Business'}
              </Typography.Text>
            </Skeleton>
          </SocialCardHead>

          <Flex gap={8} align="center">
            {showSkeleton ? (
              <SocialIconSkeleton size={40} active={showSkeleton} />
            ) : (
              <Image
                alt={''}
                width={40}
                height={40}
                style={{ borderRadius: '50%' }}
                src={data.profile_image || fallback}
              />
            )}

            <Flex vertical gap={4}>
              <Skeleton
                paragraph={false}
                active={showSkeleton}
                style={{ width: 120 }}
                loading={showSkeleton}
                title={{ style: { marginBottom: 0 } }}
              >
                <Typography.Text strong style={textStyles}>
                  {data?.full_name || ''}
                </Typography.Text>
              </Skeleton>

              <Handle>
                {dataServer ? (
                  <Flex align="center" gap={4}>
                    <Text
                      ellipsis
                      type="secondary"
                      style={textStyles}
                      className={fontVariants.opt2}
                    >
                      @{data?.handle}
                    </Text>
                    <Image src={linkIcon} alt="Open in new window" />
                  </Flex>
                ) : (
                  !!data?.link && (
                    <HandleSkeleton
                      paragraph={false}
                      active={showSkeleton}
                      style={{ width: 60 }}
                      loading={showSkeleton}
                    >
                      <Link target="_blank" href={data.link}>
                        <Flex align="center" gap={4}>
                          <Text
                            ellipsis
                            type="secondary"
                            style={textStyles}
                            className={fontVariants.opt2}
                          >
                            @{data?.handle}
                          </Text>
                          <Image src={linkIcon} alt="Open in new window" />
                        </Flex>
                      </Link>
                    </HandleSkeleton>
                  )
                )}
              </Handle>
            </Flex>
          </Flex>

          {showSkeleton ? (
            <Skeleton paragraph={{ rows: 2 }} />
          ) : (
            <Flex gap={32}>
              <Flex vertical gap={8}>
                <Typography.Text type="secondary" style={textStyles}>
                  Posts
                </Typography.Text>

                <Typography.Title style={{ margin: 0 }} level={3}>
                  {data?.media_count || 0}
                </Typography.Title>
              </Flex>

              <Flex vertical gap={8}>
                <Typography.Text type="secondary" style={textStyles}>
                  Followers
                </Typography.Text>
                <Typography.Title style={{ margin: 0 }} level={3}>
                  {abbreviateNumber(data?.followers || 0)}
                </Typography.Title>
              </Flex>

              <Flex vertical gap={8}>
                <Typography.Text type="secondary" style={textStyles}>
                  Following
                </Typography.Text>
                <Typography.Title style={{ margin: 0 }} level={3}>
                  {data?.followings || 0}
                </Typography.Title>
              </Flex>
            </Flex>
          )}
        </div>
      ) : (
        <>
          <SocialCardHead size={size} style={{ alignItems: 'center' }}>
            <HeadDetails size={size}>
              {showSkeleton ? (
                <SocialIconSkeleton size={40} active={showSkeleton} />
              ) : (
                <Image src={igIcon} alt="Instagram" />
              )}
            </HeadDetails>
          </SocialCardHead>

          {!dataServer && <ConnectedInstagram />}
        </>
      )}
    </SocialCardBody>
  );
};
