export enum AppRoutes {
  index = '/',
  base = '/home',
  home = '/home',
  templates = '/templates',
  mediaKits = '/media-kits',
  loginAndSecurity = '/login-and-security',

  instagramMC = '/home/instagram-money-calculator',
  tiktokERC = '/home/tiktok-engagement-rate-calculator',
  instagramFFC = '/home/instagram-fake-followers-checker',
  instagramERC = '/home/instagram-engagement-rate-calculator',

  login = '/login',
  registerStep1 = '/register/step-1',
  registerStep2 = '/register/step-2',
  registerStep3 = '/register/step-3',
  forgotPassword = '/forgot-password',

  mobileLogin = '/mobile-auth/login',
  mobileRegisterStep1 = '/mobile-auth/register/step-1',
  mobileRegisterStep2 = '/mobile-auth/register/step-2',
  mobileForgotPassword = '/mobile-auth/forgot-password',
}
