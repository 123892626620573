'use client';

import React from 'react';
import Image from 'next/image';
import { LockIcon } from '@shared/ui/Icons/lock-icon';
import tiktokLight from '@shared/icons/tiktok-light.svg';
import { TikTokButton } from '@shared/ui/tik-tok-button';
import twoArrows from '@shared/icons/two-arrows-icon.svg';
import logoColored from '@shared/icons/logo-xs-colored.svg';
import { SecurityIcon } from '@shared/ui/Icons/security-icon';
import { UserSafeIcon } from '@shared/ui/Icons/user-safe-icon';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { useTiktokRedirectMutation } from '@shared/services/tiktok';
import { Flex, Modal, Button, Divider, Typography, ButtonProps } from 'antd';
import styles from './connect-tik-tok.module.css';

export const ConnectedTikTok: React.FC<
  ButtonProps & { label?: React.ReactNode }
> = ({ label, ...props }) => {
  const posthog = useLocalPostHog();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [tiktokRedirect] = useTiktokRedirectMutation();

  const onClick = () => {
    setOpen(true);

    try {
      posthog?.capture(PosthogEvents.connect_tt_click);
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  const onCancel = () => {
    setOpen(false);

    try {
      posthog?.capture(PosthogEvents.tt_popup_close);
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  const onConnect = async () => {
    setLoading(true);

    try {
      const res = await tiktokRedirect().unwrap();

      try {
        posthog?.capture(PosthogEvents.tt_popup_continue_click);
      } catch (e) {
        console.error('Posthog error:', e);
      }

      if (res.data.target_url) {
        window.location.href = res.data.target_url;
      } else {
        setLoading(false);
      }
    } catch (e) {
      console.error(e);

      setLoading(false);
    }
  };

  return (
    <>
      <TikTokButton
        block
        size="large"
        type="primary"
        loading={loading}
        onClick={onClick}
        {...props}
      >
        {label || 'Connect TikTok'}
      </TikTokButton>
      <Modal
        width={390}
        open={open}
        footer={null}
        onCancel={onCancel}
        maskClosable={false}
      >
        <Flex vertical gap={24}>
          <Flex align="center" vertical gap={20}>
            <div />
            <Typography.Title level={5} style={{ fontWeight: 700, margin: 0 }}>
              TikTok Verified Partner
            </Typography.Title>
          </Flex>
          <Flex gap={10} align="center" justify="center">
            <div className={styles.logoWrapper}>
              <Image
                width={24}
                height={24}
                src={logoColored}
                alt="viralmango logo"
              />
            </div>

            <Image src={twoArrows} alt="two arrows icon" />

            <div className={styles.logoWrapper}>
              <Image
                width={24}
                height={24}
                alt="tiktok logo"
                src={tiktokLight}
              />
            </div>
          </Flex>
        </Flex>

        <Divider />

        <Flex vertical gap={24}>
          <Flex align="center" vertical>
            <Typography.Title
              level={5}
              style={{ margin: 0, textAlign: 'center' }}
            >
              ViralMango wants to link your
              <br />
              account
            </Typography.Title>
          </Flex>

          <Flex vertical gap={24}>
            <Flex gap={10} align="flex-start">
              <Flex>
                <UserSafeIcon />
              </Flex>
              <Flex vertical>
                <Typography.Text strong>
                  Your account is in safe hands
                </Typography.Text>
                <Typography.Text>
                  We can’t post, modify or delete your account data.
                </Typography.Text>
              </Flex>
            </Flex>

            <Flex gap={10} align="flex-start">
              <Flex>
                <LockIcon />
              </Flex>
              <Flex vertical>
                <Typography.Text strong>Your consent matters</Typography.Text>
                <Typography.Text>
                  We only fetch the data that you have given approval for.
                </Typography.Text>
              </Flex>
            </Flex>

            <Flex gap={10} align="flex-start">
              <Flex>
                <SecurityIcon />
              </Flex>
              <Flex vertical>
                <Typography.Text strong>
                  Your data is safe and secure
                </Typography.Text>
                <Typography.Text>
                  We use best practices for data encryption and storage.
                </Typography.Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Flex vertical gap={20}>
          <div className={styles.footer}>
            <Typography.Text className={styles.privacyPolicy}>
              By clicking on “Continue” you agree to ViralMango’s{' '}
              <a
                target="_blank"
                className={styles.link}
                href="https://viralmango.com/privacy-policy/"
              >
                <b>Privacy Policy</b>
              </a>{' '}
              and{' '}
              <a
                target="_blank"
                className={styles.link}
                href="https://viralmango.com/privacy-policy/"
              >
                <b>Terms of Use.</b>
              </a>
            </Typography.Text>
          </div>
          <Button
            block
            size="large"
            type="primary"
            loading={loading}
            onClick={onConnect}
          >
            Continue
          </Button>
        </Flex>
      </Modal>
    </>
  );
};
