import React from 'react';
import { Flex } from 'antd';
import { Resize12 } from './ui/resize12';
import { Resize22 } from './ui/resize22';
import { Resize14 } from './ui/resize14';
import { Resize24 } from './ui/resize24';
import { MoveAction } from './ui/move-action';
import { DoneAction } from './ui/done-action';
import { DeleteAction } from './ui/delete-action';
import { ResizeActions } from './ui/resize-actions';
import { profileAvatarIndex } from '@shared/constants';
import { AllowedSizes } from '@widgets/mediakit/helpers';
import { LinkBottomActions } from './ui/link-bottom-actions';
import { TextBottomActions } from './ui/text-bottom-actions';
import { TitleBottomActions } from './ui/title-bottom-actions';
import { ImageBottomActions } from './ui/image-bottom-actions';
import { VideoBottomActions } from './ui/video-bottom-actions';
import type { ActionsProps, BottomActionsProps } from './types';
import { ComponentNames } from '@shared/constants/componentNames';
import { ProfileBottomActions } from './ui/profile-bottom-actions';
import { profileAvatarStyles } from '@shared/constants/profileSizes';
import { builderCardSizes } from '@shared/constants/builderCardSizes';
import { BottomActionsContainer } from './ui/bottom-actions-container';
import { ServiceListBottomActions } from './ui/service-list-bottom-actions';
import {
  useSelector,
  selectProfile,
  selectActiveItem,
  selectCurrentLayout,
} from '@shared/redux';

export const actions = {
  [builderCardSizes.s12]: Resize12,
  [builderCardSizes.s14]: Resize14,
  [builderCardSizes.s22]: Resize22,
  [builderCardSizes.s24]: Resize24,
};

export const Actions: React.FC<ActionsProps> = ({
  index,
  dataGrid,
  allowedSizes,
  extraActions,
  disableActions,
}) => {
  const activeItem = useSelector(selectActiveItem);

  const isActive = activeItem === dataGrid.i;

  return (
    <>
      <MoveAction isActive={isActive} index={index} />
      <DeleteAction id={dataGrid.i} isActive={isActive} index={index} />
      <ResizeActions
        index={index}
        dataGrid={dataGrid}
        allowedSizes={allowedSizes}
        extraActions={extraActions}
        disableActions={disableActions}
      />
    </>
  );
};

export const BottomActions: React.FC<BottomActionsProps> = ({
  slug,
  username,
}) => {
  const profile = useSelector(selectProfile);
  const layout = useSelector(selectCurrentLayout);
  const activeItem = useSelector(selectActiveItem);
  const styles = profileAvatarStyles[profile.avatar_shape!];

  if (!activeItem) {
    return null;
  }

  const activeIndex = layout.findIndex((e) => e.i === activeItem);

  const type = activeItem?.split('_+_')?.[0];

  const dataGrid = layout[activeIndex];

  let activeSize = `${dataGrid?.w}${dataGrid?.h}`;

  let element;

  switch (type) {
    case profileAvatarIndex: {
      activeSize = `${styles.width}${styles.height}`;

      element = (
        <ProfileBottomActions
          slug={slug}
          username={username}
          activeSize={activeSize}
        />
      );

      break;
    }
    case ComponentNames.basics_title: {
      element = (
        <BottomActionsContainer
          isActive
          id="resizeMobileTarget"
          className="actions-container"
        >
          <TitleBottomActions />
        </BottomActionsContainer>
      );
      break;
    }
    case ComponentNames.youtube_views_growth:
    case ComponentNames.youtube_followers_growth:
    case ComponentNames.youtube_audience_genders_per_age:
    //
    case ComponentNames.tiktok_likes_growth:
    case ComponentNames.tiktok_followers_growth:
    case ComponentNames.tiktok_audience_genders_per_age:
    //
    case ComponentNames.instagram_likes_growth:
    case ComponentNames.instagram_followers_growth:
    case ComponentNames.instagram_audience_genders_per_age: {
      element = (
        <BottomActionsContainer
          isActive
          id="resizeMobileTarget"
          className="actions-container"
        >
          <div />
          <DoneAction />
        </BottomActionsContainer>
      );

      break;
    }
    case ComponentNames.basics_service_list: {
      element = (
        <BottomActionsContainer
          isActive
          id="resizeMobileTarget"
          className="actions-container"
        >
          <ServiceListBottomActions
            slug={slug}
            username={username}
            activeSize={activeSize}
          />
        </BottomActionsContainer>
      );

      break;
    }
    case ComponentNames.basics_link: {
      element = (
        <BottomActionsContainer
          isActive
          id="resizeMobileTarget"
          className="actions-container"
        >
          <LinkBottomActions
            slug={slug}
            username={username}
            activeSize={activeSize}
          />
        </BottomActionsContainer>
      );

      break;
    }
    case ComponentNames.basics_image: {
      element = (
        <BottomActionsContainer
          isActive
          id="resizeMobileTarget"
          className="actions-container"
        >
          <ImageBottomActions
            slug={slug}
            username={username}
            activeSize={activeSize}
          />
        </BottomActionsContainer>
      );

      break;
    }
    case ComponentNames.basics_video: {
      element = (
        <BottomActionsContainer
          isActive
          id="resizeMobileTarget"
          className="actions-container"
        >
          <VideoBottomActions
            slug={slug}
            username={username}
            activeSize={activeSize}
          />
        </BottomActionsContainer>
      );

      break;
    }
    case ComponentNames.basics_text: {
      element = (
        <BottomActionsContainer
          id="resizeMobileTarget"
          isActive
          className="actions-container"
        >
          <TextBottomActions
            slug={slug}
            username={username}
            activeSize={activeSize}
          />
        </BottomActionsContainer>
      );

      break;
    }
    default: {
      const sizes = AllowedSizes[type as ComponentNames];
      element = (
        <BottomActionsContainer
          isActive
          id="resizeMobileTarget"
          className="actions-container"
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: '.6rem' }}>
            {sizes.map((size) => {
              const Action = actions[size];

              return (
                <Action
                  key={size}
                  index={activeIndex}
                  isActive={activeSize === size}
                />
              );
            })}
          </div>
          <DoneAction />
        </BottomActionsContainer>
      );

      break;
    }
  }

  return element;
};
