'use client';

import React from 'react';
import Image from 'next/image';
import { LockIcon } from '@shared/ui/Icons/lock-icon';
import twoArrows from '@shared/icons/two-arrows-icon.svg';
import logoColored from '@shared/icons/logo-xs-colored.svg';
import { InstagramButton } from '@shared/ui/instagram-button';
import { SecurityIcon } from '@shared/ui/Icons/security-icon';
import { UserSafeIcon } from '@shared/ui/Icons/user-safe-icon';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import meta from '@shared/icons/Meta_lockup_positive primary_RGB 1.svg';
import { useInstagramRedirectMutation } from '@shared/services/instagram';
import { Flex, Modal, Divider, Typography, Button, ButtonProps } from 'antd';
import styles from './connect-instagram.module.css';

export const ConnectedInstagram: React.FC<
  ButtonProps & { label?: React.ReactNode }
> = ({ label, ...props }) => {
  const posthog = useLocalPostHog();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [instagramRedirect] = useInstagramRedirectMutation();

  const onClick = () => {
    setOpen(true);

    try {
      posthog?.capture(PosthogEvents.connect_ig_click);
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  const onCancel = () => {
    setOpen(false);

    try {
      posthog?.capture(PosthogEvents.ig_popup_close);
    } catch (e) {
      console.error('Posthog error:', e);
    }
  };

  const onConnect = async () => {
    setLoading(true);

    try {
      const res = await instagramRedirect().unwrap();

      try {
        posthog?.capture(PosthogEvents.ig_popup_continue_click);
      } catch (e) {
        console.error('Posthog error:', e);
      }

      if (res.data.target_url) {
        window.location.href = res.data.target_url;
      } else {
        setLoading(false);
      }
    } catch (e) {
      console.error(e);

      setLoading(false);
    }
  };

  return (
    <>
      <InstagramButton
        block
        size="large"
        type="primary"
        loading={loading}
        onClick={onClick}
        {...props}
      >
        {label || 'Connect Instagram'}
      </InstagramButton>
      <Modal
        width={390}
        open={open}
        footer={null}
        onCancel={onCancel}
        maskClosable={false}
      >
        <Flex vertical gap={24}>
          <Flex align="center" vertical gap={20}>
            <div />
            <Typography.Title level={5} style={{ fontWeight: 700, margin: 0 }}>
              Meta Verified Tech Provider
            </Typography.Title>
          </Flex>
          <Flex gap={10} align="center" justify="center">
            <div className={styles.logoWrapper}>
              <Image src={logoColored} alt="viralmango logo" />
            </div>

            <Image src={twoArrows} alt="two arrows icon" />

            <div className={styles.logoWrapper}>
              <Image src={meta} alt="meta logo" />
            </div>
          </Flex>
        </Flex>

        <Divider />

        <Flex vertical gap={24}>
          <Flex align="center" vertical>
            <Typography.Title
              level={5}
              style={{ margin: 0, textAlign: 'center' }}
            >
              ViralMango wants to link your
              <br />
              account
            </Typography.Title>
          </Flex>

          <Flex vertical gap={24}>
            <Flex gap={10} align="flex-start">
              <Flex>
                <UserSafeIcon />
              </Flex>
              <Flex vertical>
                <Typography.Text strong>
                  Your account is in safe hands
                </Typography.Text>
                <Typography.Text>
                  We can’t post, modify or delete your account data.
                </Typography.Text>
              </Flex>
            </Flex>

            <Flex gap={10} align="flex-start">
              <Flex>
                <LockIcon />
              </Flex>
              <Flex vertical>
                <Typography.Text strong>Your consent matters</Typography.Text>
                <Typography.Text>
                  We only fetch the data that you have given approval for.
                </Typography.Text>
              </Flex>
            </Flex>

            <Flex gap={10} align="flex-start">
              <Flex>
                <SecurityIcon />
              </Flex>
              <Flex vertical>
                <Typography.Text strong>
                  Your data is safe and secure
                </Typography.Text>
                <Typography.Text>
                  We use best practices for data encryption and storage.
                </Typography.Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Flex vertical gap={20}>
          <div className={styles.footer}>
            <Typography.Text className={styles.privacyPolicy}>
              By clicking on “Continue” you agree to ViralMango’s{' '}
              <a
                target="_blank"
                className={styles.link}
                href="https://viralmango.com/privacy-policy/"
              >
                <b>Privacy Policy</b>
              </a>{' '}
              and{' '}
              <a
                target="_blank"
                className={styles.link}
                href="https://viralmango.com/privacy-policy/"
              >
                <b>Terms of Use.</b>
              </a>
            </Typography.Text>
          </div>
          <Button
            block
            size="large"
            type="primary"
            loading={loading}
            onClick={onConnect}
          >
            Continue
          </Button>
        </Flex>
      </Modal>
    </>
  );
};
