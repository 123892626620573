'use client';

import React from 'react';
import { v4 as uuidV4 } from 'uuid';
import { Box } from '@shared/ui/box';
import styled, { CSSObject } from '@emotion/styled';
import fallback from '@shared/icons/tik-tok-profile-image.svg';
import ImageWithFallback from '@shared/ui/image-with-fallback';
import { PostSkeleton } from '@widgets/social-card/ui/post-skeleton';
import { builderCardSizes } from '@shared/constants/builderCardSizes';

const keys = [uuidV4(), uuidV4(), uuidV4()];

const fallbackPosts = [{ image: '' }, { image: '' }, { image: '' }];

const ImageCover = styled('span')(() => ({
  top: 0,
  left: 0,
  zIndex: 1,
  width: '100%',
  height: '100%',
  display: 'flex',
  userSelect: 'none',
  position: 'absolute',
  backgroundColor: 'transparent',
}));

const PostsContainer = styled('div')(() => ({
  flexGrow: 1,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  justifyContent: 'space-between',
}));

const postStyles = (
  size: builderCardSizes,
): {
  imageProps: {
    width: number;
    height: number;
    styles: React.CSSProperties;
  };
  containerStyles: CSSObject;
}[] => {
  const isS14 = size === builderCardSizes.s14;
  const isS24 = size === builderCardSizes.s24;

  let imageSize = { width: 66, height: 101 };

  if (isS24) {
    imageSize = { height: 163, width: 107 };
  }

  const transform = isS14
    ? 'scaleX(1.25) scaleY(1.25)'
    : 'translate(-50%,0) scaleX(1.25) scaleY(1.25)';

  return [
    {
      containerStyles: {
        zIndex: 1,
        width: 'unset',
        position: 'relative',
        display: isS14 ? 'none' : 'flex',
      },
      imageProps: {
        width: imageSize.width,
        height: imageSize.height,
        styles: {
          objectFit: 'cover',
          borderRadius: '8px',
          boxShadow: '0 0 0 4px #fff',
        },
      },
    },
    {
      containerStyles: {
        zIndex: 2,
        transform,
        width: 'unset',
        borderRadius: '8px',
        left: isS14 ? 0 : '50%',
        position: isS14 ? 'relative' : 'absolute',
      },
      imageProps: {
        width: imageSize.width,
        height: imageSize.height,
        styles: {
          objectFit: 'cover',
          borderRadius: '8px',
          boxShadow: '0 0 0 4px #fff',
        },
      },
    },
    {
      containerStyles: {
        zIndex: 1,
        width: 'unset',
        position: 'relative',
      },
      imageProps: {
        width: imageSize.width,
        height: imageSize.height,
        styles: {
          objectFit: 'cover',
          borderRadius: '8px',
          boxShadow: '0 0 0 4px #fff',
        },
      },
    },
  ];
};

const Posts: React.FC<{
  size: builderCardSizes;
  showSkeleton?: boolean;
  posts?: { link?: string; image: string }[];
}> = ({ size, posts, showSkeleton }) => {
  const postProps = postStyles(size);
  const isS12 = size !== builderCardSizes.s12;

  posts = posts?.slice(0, 3);
  if (!posts?.length) {
    posts = fallbackPosts;
  }

  return isS12 ? (
    showSkeleton ? (
      <PostsContainer>
        <ImageCover />
        {keys.map((uuid, index) => {
          const { imageProps, containerStyles } = postProps[index];

          return (
            <Box key={uuid} boxStyles={containerStyles}>
              <PostSkeleton
                size={size}
                paragraph={false}
                active={showSkeleton}
                style={{
                  width: imageProps.width,
                  height: imageProps.height,
                }}
              />
            </Box>
          );
        })}
      </PostsContainer>
    ) : (
      <PostsContainer>
        <ImageCover />
        {posts?.map(({ image }, index) => {
          const { imageProps, containerStyles } = postProps[index];
          return (
            <Box key={keys[index]} boxStyles={containerStyles}>
              <ImageWithFallback
                quality={100}
                alt="TikTok video"
                fallbackSrc={fallback}
                width={imageProps.width}
                style={imageProps.styles}
                height={imageProps.height}
                src={image?.trim() || fallback}
              />
            </Box>
          );
        })}
      </PostsContainer>
    )
  ) : null;
};

export const MemoTikTokPosts = React.memo(Posts);
