import { api } from './api';
import { ApiEndpoints } from '@shared/constants/apiEndpoints';
import { transformErrorResponse } from '@shared/utils/transformErrorResponse';

export const tiktokApi = api.injectEndpoints({
  endpoints: (builder) => ({
    tiktokRedirect: builder.mutation<{ data: { target_url: string } }, void>({
      transformErrorResponse,
      query: () => ({
        method: 'GET',
        url: ApiEndpoints.tiktokRedirect,
      }),
    }),
    tiktokCallback: builder.query<
      any,
      {
        code: string;
        state: string;
        scopes: string;
      }
    >({
      transformErrorResponse,
      query: ({ code, state, scopes }) => ({
        method: 'GET',
        url: `${ApiEndpoints.tiktokCallback}?code=${code}&state=${state}&scopes=${scopes}`,
      }),
    }),
    tiktokDisconnect: builder.mutation<any, void>({
      transformErrorResponse,
      query: () => ({
        method: 'DELETE',
        url: ApiEndpoints.tiktokDisconnect,
      }),
    }),
  }),
});

export const {
  useTiktokCallbackQuery,
  useTiktokRedirectMutation,
  useTiktokDisconnectMutation,
} = tiktokApi;
