import React from 'react';

export function AbcOutlined() {
  return (
    <svg
      width="32"
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="white"
        d="M24.5 9.25V5.25C24.5 5.1125 24.3875 5 24.25 5H7.25C7.1125 5 7 5.1125 7 5.25V9.25C7 9.3875 7.1125 9.5 7.25 9.5H9C9.1375 9.5 9.25 9.3875 9.25 9.25V7.25H14.5V24.75H11.625C11.4875 24.75 11.375 24.8625 11.375 25V26.75C11.375 26.8875 11.4875 27 11.625 27H19.875C20.0125 27 20.125 26.8875 20.125 26.75V25C20.125 24.8625 20.0125 24.75 19.875 24.75H17V7.25H22.25V9.25C22.25 9.3875 22.3625 9.5 22.5 9.5H24.25C24.3875 9.5 24.5 9.3875 24.5 9.25Z"
      />
    </svg>
  );
}
