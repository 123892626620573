import React from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const LockSvg = () => (
  <svg
    width="16"
    fill="none"
    height="20"
    viewBox="0 0 16 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="black"
      d="M13 7.99003V5.00002C13 3.67393 12.4732 2.40216 11.5355 1.46447C10.5979 0.526786 9.32609 0 8 0C6.67391 0 5.40214 0.526786 4.46446 1.46447C3.52677 2.40216 2.99998 3.67393 2.99998 5.00002V7.99003C1.52 8.73 0.5 10.24 0.5 12V15C0.501798 16.193 0.976481 17.3365 1.82001 18.18C2.66353 19.0236 3.80708 19.4982 5 19.5H11C12.1929 19.4982 13.3365 19.0236 14.18 18.18C15.0235 17.3365 15.4982 16.193 15.5 15V12C15.5 10.24 14.48 8.73 13 7.99003ZM8.99998 14C8.99998 14.2652 8.89463 14.5196 8.7071 14.7071C8.51956 14.8946 8.26521 15 8 15C7.73479 15 7.48044 14.8946 7.2929 14.7071C7.10537 14.5196 7.00002 14.2652 7.00002 14V13C7.00002 12.7348 7.10537 12.4805 7.2929 12.2929C7.48044 12.1054 7.73479 12 8 12C8.26521 12 8.51956 12.1054 8.7071 12.2929C8.89463 12.4805 8.99998 12.7348 8.99998 13V14ZM11 7.50005H5V5.00002C5 4.20437 5.31607 3.4413 5.87868 2.8787C6.44129 2.31609 7.20435 2.00002 8 2.00002C8.79565 2.00002 9.55871 2.31609 10.1213 2.8787C10.6839 3.4413 11 4.20437 11 5.00002V7.50005Z"
    />
  </svg>
);

export const LockIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={LockSvg} {...props} />
);
