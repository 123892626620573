import React from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const UserSafeSvg = () => (
  <svg
    width="18"
    fill="none"
    height="20"
    viewBox="0 0 18 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="black"
      fillOpacity="0.88"
      d="M9.017 0C6.19164 1.47702 3.14708 2.48996 0 3V8.889C0 15.556 5.667 18.889 9 20C12.333 18.889 18 15.556 18 8.889V3C14.8619 2.49745 11.8273 1.48402 9.017 0ZM9.006 5.5C9.43123 5.5 9.84691 5.6261 10.2005 5.86234C10.554 6.09859 10.8296 6.43437 10.9923 6.82723C11.1551 7.22009 11.1976 7.65239 11.1147 8.06944C11.0317 8.4865 10.827 8.8696 10.5263 9.17028C10.2256 9.47096 9.8425 9.67573 9.42544 9.75869C9.00839 9.84165 8.57609 9.79907 8.18323 9.63634C7.79037 9.47361 7.45459 9.19804 7.21834 8.84448C6.98209 8.49091 6.856 8.07523 6.856 7.65C6.85653 7.07995 7.08322 6.53339 7.48631 6.13031C7.88939 5.72722 8.43595 5.50053 9.006 5.5ZM12.5 14C12.5 14.1326 12.4473 14.2598 12.3536 14.3536C12.2598 14.4473 12.1326 14.5 12 14.5H6C5.86739 14.5 5.74021 14.4473 5.64645 14.3536C5.55268 14.2598 5.5 14.1326 5.5 14V13.7C5.49747 13.3312 5.57089 12.9658 5.7157 12.6266C5.8605 12.2874 6.07359 11.9816 6.3417 11.7283C6.6098 11.475 6.92718 11.2797 7.27407 11.1544C7.62095 11.0291 7.98992 10.9765 8.358 11H9.642C10.0101 10.9765 10.379 11.0291 10.7259 11.1544C11.0728 11.2797 11.3902 11.475 11.6583 11.7283C11.9264 11.9816 12.1395 12.2874 12.2843 12.6266C12.4291 12.9658 12.5025 13.3312 12.5 13.7V14Z"
    />
  </svg>
);

export const UserSafeIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={UserSafeSvg} {...props} />
);
